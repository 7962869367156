body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: rgba(255, 255, 255, 0.92);
	background-color: #1f1f1f;
	margin: 0;
}

#root {
	display: flex;
	flex-direction: column;
	height: 100vh;
	min-height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h1,
h2,
h3,
p,
a {
	margin: 0;
	padding: 0;
}

h1 {
	font-weight: 600;
	font-size: 2.5em;
}

h2 {
	font-weight: 600;
	font-size: 2em;
}

h3 {
	font-weight: 600;
	font-size: 1.3em;
}

p {
	font-size: 1em;
	line-height: 1.5;
}

a {
	text-decoration: none;
	color: inherit;
}

strong {
	font-size: 1em;
	font-weight: 600;
}

hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	opacity: 0.25;
}

::selection {
	background: rgba(0, 0, 0, 0.1);
}
